import { useParams, useLocation } from 'react-router-dom';
import React, { useContext, useEffect, useState, useRef } from 'react';
import LanguageContext from 'language-context';
import Menu from 'components/menu';
import PageHeader from 'components/page_header';
import PBIEmbedd from './pbi_embedd';
import Info from 'components/info';
import WidgetHeader from 'components/widget_header';
import WidgetFooter from 'components/widget_footer';
import {
  printBVApi,
  getReports,
  getBookmarks,
  saveBookmark,
  deleteBookmark,
  updateBookmark,
} from 'store/pbi/tasks';
import Popup from 'components/popup';
import { connect } from 'react-redux';
import { models } from 'powerbi-client';
import ReportsLibrary from 'components/reports/reports_library';
import Icon from 'components/icon';

const parseFilters = (filterName, filterValue, reportData, user) => {
  if (!reportData || (!filterName && filterValue === undefined)) return [];

  const filterSchema = {
    $schema: 'http://powerbi.com/product/schema#basic',
    target: {
      table: '',
      column: '',
    },
    operator: 'In',
    values: [],
    filterType: models.FilterType.BasicFilter,
    requireSingleSelection: true,
  };

  const matchingFilter = reportData.filters.find(
    (filter) => filter.name === filterName
  );

  if (!matchingFilter) {
    //Special super temporary default filter
    if (reportData.id === Number(27)) {
      const pbiFilter = {
        ...filterSchema,
        target: {
          table: 'API_VehicleInfoBP_Stream',
          column: 'org',
        },
        values: [String(user.info.orgNr)],
      };

      return [pbiFilter];
    }
    return [];
  }

  const pbiFilter = {
    ...filterSchema,
    target: {
      table: matchingFilter.table,
      column: matchingFilter.column,
    },
    values: [filterValue],
  };

  return [pbiFilter];
};

const PrintPopup = ({ setShowPopup, startPrinting, name, orgnr }) => {
  const tc = useContext(LanguageContext);
  const defaultName = `${name}`;
  const [text, setText] = useState(defaultName);

  const handleSubmit = () => {
    if (text) {
      startPrinting(text);

      setShowPopup(false);
    }
  };

  return (
    <Popup close={() => setShowPopup(false)}>
      <WidgetHeader headline={tc.chooseFilename} />
      <input
        type="text"
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <WidgetFooter
        buttonOneText={tc.save}
        buttonOneFunc={handleSubmit}
        buttonTwoText={tc.cancel}
        buttonTwoFunc={() => setShowPopup(false)}
      />
    </Popup>
  );
};

const BookmarkPopup = ({
  setShowBookmarkPopup,
  reportId,
  getBookmarkState,
  user,
  onSave,
}) => {
  const tc = useContext(LanguageContext);
  const defaultName = tc.myBookmark;
  const [text, setText] = useState(defaultName);

  const handleSubmit = async () => {
    if (text) {
      const state = await getBookmarkState();

      await saveBookmark(text, state, reportId, user.info.id);

      await onSave();

      setShowBookmarkPopup(false);
    }
  };

  return (
    <Popup close={() => setShowBookmarkPopup(false)}>
      <WidgetHeader headline={tc.nameBookmark} />
      <input
        type="text"
        onChange={(e) => setText(e.target.value)}
        value={text}
      />
      <WidgetFooter
        buttonOneText={tc.save}
        buttonOneFunc={handleSubmit}
        buttonTwoText={tc.cancel}
        buttonTwoFunc={() => setShowBookmarkPopup(false)}
      />
    </Popup>
  );
};

const Reports = ({ user }) => {
  const tc = useContext(LanguageContext);
  const { search } = useLocation();

  const query = new URLSearchParams(search);

  const reportEl = document.querySelector('.pbiEmbeddWrapper__pbiEmbedd');

  const filterName = query.get('filterName') || null;
  const filterValue = query.get('filterValue') || null;

  const [error, setError] = useState(false);
  const [report, setUIReport] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [showBookmarkPopup, setShowBookmarkPopup] = useState(false);
  const [reports, setReports] = useState(null);
  const [reportId, setReportId] = useState(query.get('rapport') || null);
  const [allBookmarks, setAllBookmarks] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const isFetchingReports = useRef(false);
  const isFetchingBookmarks = useRef(false);

  const reportData = reports?.find((report) => report.id === Number(reportId));

  const filters = parseFilters(filterName, filterValue, reportData, user);

  const reportBookmarks =
    allBookmarks
      ?.filter((bookmark) => {
        return bookmark.reportId === reportData?.id;
      })
      .map((obj) => ({
        label: obj.name,
        onClick: () => applyBookmarkState(obj.bookmarkState),
        type: 'withDeleteButton',
        id: obj.bookmarkId,
        onDelete: () => onDeleteBookmark(obj.bookmarkId),
        onEdit: () => onEditBookmark(obj.bookmarkId, obj.name, obj.comment),
      })) || [];

  useEffect(() => {
    if (!user?.info?.id) return;

    const getReportsAndBookmarksAsync = async () => {
      isFetchingReports.current = true;
      const data = await getReports(user.info.id);

      if (data) {
        setReports(data);
        isFetchingReports.current = false;
      }

      isFetchingBookmarks.current = true;
      const bookmarks = await getBookmarks(user.info.id);

      if (bookmarks) {
        setAllBookmarks(bookmarks);
        isFetchingBookmarks.current = false;
      }
    };

    if (!isFetchingReports.current) {
      getReportsAndBookmarksAsync();
    }
  }, [user.info.id]);

  const applyBookmarkState = async (state) => {
    if (!report) {
      return;
    }
    await report.bookmarksManager.applyState(state);
  };

  const getBookmarkState = async () => {
    const { state } = await report.bookmarksManager.capture();

    return state;
  };

  const startPrinting = async (filename) => {
    const state = await getBookmarkState();

    await printBVApi(reportData, filename, state, user.info.id);
  };

  const onSaveBookmark = async () => {
    isFetchingBookmarks.current = true;
    const bookmarks = await getBookmarks(user.info.id);

    if (bookmarks) {
      setAllBookmarks(bookmarks);
      isFetchingBookmarks.current = false;
    }
  };

  const onDeleteBookmark = async (bookmarkId) => {
    await deleteBookmark(bookmarkId, user.info.id);

    setAllBookmarks(
      allBookmarks.filter((bookmark) => bookmark.bookmarkId !== bookmarkId)
    );
  };

  const onEditBookmark = async (bookmarkId, name, comment) => {
    const state = await getBookmarkState();
    await updateBookmark(
      bookmarkId,
      reportData.id,
      state,
      user.info.id,
      name,
      comment
    );

    isFetchingBookmarks.current = true;
    const bookmarks = await getBookmarks(user.info.id);

    if (bookmarks) {
      setAllBookmarks(bookmarks);
      isFetchingBookmarks.current = false;
    }
  };

  return (
    <div className="pbiEmbeddWrapper">
      <PageHeader headline={tc.reports} />
      {reportData && (
        <Menu
          items={[
            {
              label: tc.pdfPrint,
              type: 'button',
              onClick: () => setShowPopup(true),
            },
            {
              label: tc.saveBookmark,
              type: 'button',
              onClick: () => setShowBookmarkPopup(true),
            },
            {
              label: 'Fullskärm',
              type: 'button',
              onClick: async () => {
                if (reportEl) {
                  await reportEl.requestFullscreen();
                  setIsFullscreen(true);
                }
              },
            },
            reportBookmarks
              ? {
                  label: tc.myBookmarks,
                  items: reportBookmarks,
                  type: 'dropdown',
                }
              : {},
          ]}
          autoClose={true}
        />
      )}
      {reports && !reportData && (
        <ReportsLibrary reports={reports} setReportId={setReportId} />
      )}
      {reportData && (
        <div className="pbiEmbeddWrapper__pbiEmbedd">
          <div className="pbiEmbeddWrapper__pbiEmbedd__libraryLink">
            <Icon val="arrowBack" onClick={() => setReportId(null)} />
            <h4 onClick={() => setReportId(null)}>{tc.reportsLibrary}</h4>
          </div>
          {reportData?.name && <WidgetHeader headline={reportData.name} />}
          {reportData && user && (
            <PBIEmbedd
              setReport={setUIReport}
              reportData={reportData}
              filterInUse={filters}
              userId={user.info.id}
              setReportId={setReportId}
            />
          )}
          {showPopup && (
            <PrintPopup
              setShowPopup={setShowPopup}
              startPrinting={startPrinting}
              name={reportData.name}
            />
          )}
          {showBookmarkPopup && (
            <BookmarkPopup
              setShowBookmarkPopup={setShowBookmarkPopup}
              reportId={reportData.id}
              getBookmarkState={getBookmarkState}
              user={user}
              onSave={onSaveBookmark}
            />
          )}
          {isFullscreen && (
            <span
              className="pbiEmbeddWrapper__pbiEmbedd__fullscreenClose"
              onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen();
                  setIsFullscreen(false);
                }
              }}
            >
              X
            </span>
          )}
        </div>
      )}
    </div>
  );
};

const MapStateToProps = (state) => {
  return {
    user: state.user,
    reports: state.pbi.reports,
    reportData: state.pbi.report,
  };
};

export default connect(MapStateToProps)(Reports);
